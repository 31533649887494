/* .show ul::-webkit-scrollbar {
    background-color: #eaeaea;
    width: 5px
}

.show ul::-webkit-scrollbar-thumb {
    background-color: #a6a6a6
} */
body{
    height: 100vh;
    background-color: #203764;
    font-family: 'Roboto', sans-serif !important;
}
body::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

body::-webkit-scrollbar-track {
    background: #eaeaea;
}

body::-webkit-scrollbar-thumb {
    background-color: #a6a6a6;
    /* border-radius: 20px; */
}

body::-webkit-scrollbar-track-piece {
    background-color: #eaeaea;
    ;
}

body::-webkit-scrollbar-track-piece:end {
    margin-right: 50px;
}

body::-webkit-scrollbar-track-piece:start {
    margin-left: 50px;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: #eaeaea;
    ;
}

::-webkit-scrollbar-thumb {
    background-color: #a6a6a6;
    /* border-radius: 10px; */
}

::-webkit-scrollbar-track-piece {
    background-color: #eaeaea;
    ;
}

.body::-webkit-scrollbar-track-piece:end {
    margin-right: 50px;
}

.body::-webkit-scrollbar-track-piece:start {
    margin-left: 50px;
}