.main-content{
    display: grid;
}
.tabs-header {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.tab {
    text-decoration: none;
    font-weight: 600;
    background-color: #0E192C;
    color: #90B4D5;
    font-size: 18px;
    justify-content: center;
    cursor: pointer;
    border: 2px solid #28467B;
    border-radius: 5px;
    padding: 3px 14px 3px 14px;;
    margin-top: 6px;
}
.color_tab {
    text-decoration: none;
    font-weight: 600;
    background-color: #2F5597;
    color: #C8D4E9;
    font-size: 18px;
    justify-content: center;
    cursor: pointer;
    border: 2px solid #0869B6;
    border-radius: 5px;
    padding: 3px 14px 3px 14px;;
    margin-top: 6px;
}

.tab:hover {
    background-color: #2F5597;
    color: #C8D4E9;
    border: 2px solid #0869B6;
}

.block-div-insight{
    position: relative;
    width: 75px;
    height: 80px;
    min-width: 65px;
    cursor: pointer;
    color: white;
    background-color: rgb(153, 0, 0);
}
.block-active-cell-insight{
    padding: 5px;
    display: grid;
    row-gap: 3px;
}

.block-name-insight{
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    display: block;
    line-height: 12px;
    margin-bottom: 10px;
    margin-top: 6px;
}

.trend-type-name-insight{
    font-size: 8px;
    text-align: center;
    font-weight: 500;
}
/* 
.market-name {
    font-size: 7px;
    text-align: center;
    font-weight: 500;
} */

/* .pos-state-div {
    display: flex;
    justify-content: space-between;
}

.pos-state-div label {
    font-size: 7px;
    font-weight: 500;
} */

.block-name-div-insight{
    display: grid;
    row-gap: 2px;
    align-items: center;
    text-align: center;
}
.block-div-content{
    /* display: flex;
    column-gap: 12px;
    color: white;
    margin-left: 85px; */
    color: #fff;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
}
.content-text-one{
    margin-top: 41px;
    font-size: 18px;
}
.content-text-two{
    margin-top: 27px;
    font-size: 35px;
    font-weight: 700;
}
.content-part{
    border-radius: 27px 27px 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
    justify-content: space-between;
    margin-top: 20px;
}
.content-text-area{
    grid-area: area1;
    overflow-y: auto;
    color: #fff;
    height: 320px;
    margin-top: 20px;
}
.content-text-area-stategry{
    grid-area: area1;
    overflow-y: auto;
    color: #fff;
    height: 345px;
    margin-top: 25px;
}
.stategry-header{
    font-size: 18px;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
.content-text-area h2{
    font-size: 21px;
}
.content-text-area-stategry p{
    font-size: 18px;
    line-height: 20px;
}
.content-text-area-stategry h2{
    font-size: 20px;
}
.content-text-area p{
    font-size: 18px;
    line-height: 20px;
}

.image-discription-div{
    display: flex;
    color: white;
}
.image-discription-div-stategry{
    display: flex;
    color: white;
    margin-top: 5px;
}

.market-matrices-div{
    width: 30%;
    padding: 6px 11px;
}
.market-structure-div{
    width: 70%;
}
.market-expectation-div{
    width: 60%;
}
.market-expectation-div img{
    width: 100%;
}
.market-structure-div-stategry{
    width: 70%;
}
.header-div{
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-top: 14px;
}
.sub-header-div{
    margin-top: 14px;
    text-align: center;
    font-size: 18px;
}
.market-metrices-value{
    display: flex;
    justify-content: space-between;
}
.market-structure-div img{
    width: 100%;
    padding: 26px;
    padding-top: 5px;
}
.market-structure-div-stategry img{
    width: 100%;
    padding: 7px;
}

.item{
    display: grid;
    padding: 5px;
}
.area1{
    grid-area: area1;
    max-height: 100%;
    color: #fff;
    padding-top: 15px;
    font-size: 18px;
    margin: 0;
    font-size: 18px;
    line-height: 20px;
}
.red{
    background-color: red;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
}
.blue{
    background-color: #0000FF;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
}
.text-space{
    height: 14px;
}
.block-div-insight-tile{
    height: 100%;
    width: 100%;
    min-height: 100px;
    max-width: 100px;
    padding: 5px;
    border-radius: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    font-size: 30px;
    margin-right: 10px;
}
.block-active-cell-tile {
    padding: 5px;
    display: grid;
    row-gap: 3px;
}
.trend-type-name-insight-tile{
    font-size: 10px;
    text-align: center;
    font-weight: 500;
}
.block-name-div-tile {
    display: grid;
    row-gap: 2px;
    align-items: center;
    text-align: center;
}
.block-name-insight-tile {
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    display: block;
    line-height: 12px;
    margin-bottom: 10px;
    margin-top: 6px;
}
.market-name-tile {
    font-size: 10px;
    text-align: center;
    font-weight: 500;
}
.market-graph-div{
    width: 20%;
    display: grid;
    margin-top: 31px;
}
.market-graph-image-div{
    width: 100%;
    padding: 0px 10px;
    height: fit-content;
    text-align: center;
}
.market-graph-image-div img{
    width: 90%;
}
.header-span{
    font-size: 13px;
}
.footer-span{
    font-size: 11px;
    color: #8DB8EE;
    font-weight: 700;
}
.red-pastel{
    color: #F7494C;
}
.blue{
    background-color: #0000FF;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
}
.tan{
    color: #BD9128;
}
.green-text{
    color: #09E334;
}
.section-info-text-header{
    font-size: 23px;
    font-weight: 500;
}
.futures-header{
    font-size: 30px;
}

.download-header{
    color: #FF6600;
    font-size: 18px;
    font-weight: 500;
}

.download-header-ninja{
    color: #FF6600;
    font-size: 18px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 15px;
}
.main-header-div{
    margin-top: 20px;
    width: 95%;
}
.all-selector-div{
    display: flex;
    justify-content: space-between;
    color: white;
}
.all-select-input-div{
    display: flex;
    column-gap: 5px;
}
.updated-div{
    font-size: 12px;
    font-weight: 100;
    padding-left: 10px;
    color: #B4C6E7;
}
.download-row{
    margin-top: 20px;
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 50px 120px 90px 90px 90px 90px 90px;
    grid-template-rows: auto;
    grid-template-areas: "download-group-check download-group-name download-item-1 download-item-2 download-item-3 download-item-4 update-date";
    grid-gap: 2px;
    align-items: center;
    color: white;
}
.market-name-download-div{
    font-size: 16px;
    font-weight: 700;
}
.download-item-div{    
    font-size: 16px;
    font-weight: 100;
    text-decoration: underline;
    padding-left: 5px;
    padding-right: 5px;
}
.download-item-ninja-div{    
    font-size: 16px;
    font-weight: 100;
    text-decoration: underline;
    padding-left: 5px;
    padding-right: 5px;
}
.updated-date{
    grid-area: update-date;
    font-size: 12px;
    font-weight: 100;
    padding-left: 10px;
    color: #B4C6E7;
}
.spcae-item-content{
    height: 100%;
    width: 100%;
    min-height: 100px;
    max-width: 100px;
    padding: 5px;
    border-radius: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    font-size: 30px;
    margin-right: 10px;
}

.trend-item_trend{
    font-size: 10px;
    text-align: center;
}
.trend-item_type{
    font-size: 10px;
    text-align: center;
}
.options-height{
    max-height: 590px;
    overflow: auto;
}
.position_Relative{
    position: relative;
}
.r-value{
    position: absolute;
    top: 25px;
    right: 23px;
    font-size: 23px;
    font-weight: 700;
    color: #09E334;
}
.download-group {
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 150px 120px 120px 120px 120px;
    grid-template-rows: auto;
    grid-template-areas: " download-group-name download-item-1 download-item-2 download-item-4 update-date";
    grid-gap: 2px;
    align-items: center;
    color: white;
}
.download-group-ninja1
{
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 276px 120px;
    grid-template-rows: auto;
    grid-template-areas: "download-group-name download-item-1";
    grid-gap: 2px;
    align-items: center;
    color: white;
}
.download-group-ninja2
{
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 153px 120px;
    grid-template-rows: auto;
    grid-template-areas: "download-group-name download-item-1";
    grid-gap: 2px;
    align-items: center;
    color: white;
}
.download-group-ninja {
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: 153px 120px 250px;
    grid-template-rows: auto;
    grid-template-areas: "download-group-name download-item-1 download-item-2";
    grid-gap: 2px;
    align-items: center;
    color: white;
}
.download-item-4
{

    padding-left: 30px;

}
.update-date{
    grid-area: update-date;
    font-size: 12px;
    font-weight: 100;
    padding-left: 10px;
    color: #B4C6E7;
}
.download-item a {
    color: white;
}
.download-item a:hover {
    color: #0a58ca;
}

.download-item-ninja a {
    color: white;
}
.download-item-ninja a:hover {
    color: #0a58ca;
}