.dashboard-data-section {
    width: 20%;
}
.data-table-div{
    padding: 20px;
    padding-right: 0;
    width: 75%;
    padding-bottom: 0;
}
.data-table-tile{
    display: flex;
    background-color: white;
    height: 28px;
    font-weight: 700;
    font-size: 16px;
}
.data-table-tile-transparent{
    display: flex;
    height: 28px;
}
.data-table-key-1{
    text-align: start;
    width: 130px;
    padding: 3px 0;
    border-top: 1px solid #000;
    height: 100%;
    padding-left: 10px;
}
.data-table-value-1{
    text-align: end;
    width: 100%;
    border-top: 1px solid #000;
    padding-right: 11px;
    height: 100%;
}
.data-table-risk-div{
    padding: 20px;
    padding-left: 0;
    padding-bottom: 12px;
}
.risk-part{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}
.risk-header-div-content{
    font-weight: 600;
    font-size: 10px;
}
.bottom-div{
    padding-left: 20px;
    padding-right: 30px;
}
.bottom-div .one{
    background-color: white;
    text-align: center;
    margin: 0;
    font-weight: 900;
    line-height: 14px;
    padding-top: 4px;
}
.bottom-div .two{
    background-color: white;
    text-align: center;
    margin: 0;
    font-weight: 900;
    line-height: 14px;
    padding-bottom: 4px;
}
.r-div{
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
}
.risk-header-div{
    font-size: 16px;
    font-weight: 700;
}