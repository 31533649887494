.time-zone-div span {
    color: white;
}

.time-zone-div {
    margin-right: 20px;
    font-size: 16px;
    margin-left: 50px;
}

.main-logo img {
    height: 76px;

}

.main-header-bar {
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    background-image: linear-gradient(to right, #4A658E, #AEBBCE, #4A658E);
    height: 85px;
}
.header-bar-inner a{
    width: 20%;
}
.name-div{
    width: fit-content;
    padding: 0px 33px;
    margin-right: 40px;
    border-radius: 21px;
    font-size: 19px;
    color: white;
}

.header-bar-inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-block: auto;
    float: left;
    margin-top: 3px;
    align-items: center;
}
.header-content-div{
    max-width: 1620px;
    margin: 0 auto;
}
.name-log-div{
    position: relative;
    cursor: pointer;
}
.logout-div{
    position: absolute;
    left: 28px;
    background: #cfcfcf;
    padding: 3px 6px;
    border-radius: 4px;
    width: 130px;
    text-align: center;
}
.logout-div div{
    padding: 10px;
}
.logout-div div:hover{
    color: green;
}
.logout-div hr{
    margin: 0;
}