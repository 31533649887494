.dashboard-filter-div {
    position: relative;
    display: grid;
    grid-template-columns: minmax(auto,160px) 2fr 2fr 2fr 25px;
    -webkit-column-gap: 7px;
    -moz-column-gap: 7px;
    column-gap: 7px;
    grid-template-rows: 1fr;
    margin-bottom: 15px;
}

.dashboard-first-section {
    width: 40%;
}
.dropdown-select{
    width: 100%;
    height: 36px;
    border: 2px solid #28467B;
    border-radius: 5px;
    font-weight: 600;
    background-color: #0E192C;
    color: #90B4D5;
    padding: 3px 0px 4px 0px;
}
.dropdown-select-excahnges{
    width: 100%;
    height: 36px;
    border: 2px solid #28467B;
    border-radius: 5px;
    font-weight: 600;
    background-color: #0E192C;
    color: #90B4D5;
    padding: 3px 0px 4px 0px;
}
.dropdown-select-market {
    width: 100%;
    height: 36px;
    border: 2px solid #28467B;
    border-radius: 5px;
    font-weight: 600;
    background-color: #0E192C;
    color: #90B4D5;
    padding: 3px 0px 4px 0px;
}
.dropdown-select-date{
    width: fit-content;
    height: 36px;
    border: 2px solid #28467B;
    border-radius: 5px;
    font-weight: 600;
    background-color: #0E192C;
    color: #90B4D5;
    padding: 3px 12px 4px 33px;
}

.dropdown-select:focus {
    outline: 0;
    box-shadow: 0;
}

.crypto-block-div {
    width: 40px;
    height: 100px;
    border: 2px solid #28467B;
}

.block-div {
    position: relative;
    width: calc(100%/7 - 5px);
    height: 100px;
    min-width: 65px;
    cursor: pointer;
    background-color: white;
}

.block-div:hover {
    -webkit-transform: scale(.9);
    transform: scale(.9);
    -webkit-transition: .1s;
    transition: .1s;
    /* cursor: pointer; */
}

.purple-bk-lighter {
    background-color: #203764 !important;
}

.boxes-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(20, calc(100%/7));
    row-gap: 2px;
    -webkit-column-gap: 5px;
    -moz-column-gap: 5px;
    column-gap: 2px;
    max-height: 748px;
    overflow-y: auto;
    background-color: white;
}

.block-active-cell {
    padding: 5px;
    height: 100%;
}

.block-name {
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    display: block;
    line-height: 12px;
    margin-top: 8px;
}

.trend-type-name {
    font-size: 9px;
    text-align: left;
    font-weight: 400;
    margin-bottom: 2px;
}
.market-name {
    font-size: 9px;
    text-align: center;
    font-weight: 400;
}
.market-name-two {
    font-size: 10px;
    text-align: center;
    font-weight: 500;
    margin-top: 10px;
}

.pos-state-div {
    display: flex;
    justify-content: space-between;
    margin-top: 3px;
}

.pos-state-div label {
    font-size: 9px;
    font-weight: 500;
}

.block-name-div {
    display: grid;
    align-items: center;
    text-align: center;
}
.date-dropdown{
    position: relative;
    display: flex;
}
.calender-icon{
    position: absolute;
    left: 10px;
    top: 4px;
}
.refresh-icon{
    width: 21px;
    margin-top: 5px;
    margin-left: 7px;
}