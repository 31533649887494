.dashboard-main-container {
    background-color: #203764;
    min-height: 100px;
    padding: 15px;
    max-height: 850px;
    height: 100%;
}
.dashboard-main-container-content{
    display: flex;
    justify-content: space-between;
    max-width: 1620px;
    margin: 0 auto;
}

.page-jss-loading {
    margin: 0 auto;
    text-align: center;
    width: fit-content;
    padding-top: 45vh;
    color: white;
}
.page-jss-loading h1{
    font-size: 21px;
}

.page-jss-loading::before {
    content: "";
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: #07d;
    border-bottom-color: #07d;
    animation: spinner .8s ease infinite;
}
@-webkit-keyframes spinner {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spinner {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.symbolInfoTitleSymbol {
    font-size: 30px;
}

.symbolInfoTitleSymbolFutures {
    font-size: 24px;
}


.symbolInfoTitleSmall {
    font-size: 22px;
    padding: 0 15px 0 15px;
}

.symbolInfoTitleSmallFutures {
    font-size: 28px;
}